import * as React from "react";
import { Box, Tab, Unstable_Grid2 as Grid, Container, Stack } from "@mui/material";

import useRole from "hooks/useRole";
import KjosAuthorized from "components/KjosAuthorized";
import KjosHeader from "components/KjosHeader";
import KjosTitle from "components/KjosTitle";
import DescriptionIcon from '@mui/icons-material/Description';


import {  Button, SvgIcon, Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Link } from "react-router-dom";
import KjosList2 from "components/KjosList2";
import KjosSnacBar from "components/KjosSnackBar";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "hooks/useHttp";
import {
   ADMIN_ARTICLE_URL,
  ADMIN_SIGNALLEMENT_QUERY_URL,
} from "config/RoutesUrl";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import EyeIcon from "@heroicons/react/24/solid/EyeIcon";
import useSignalement from "hooks/useSignalement";
import useUser from "hooks/useUser";
import useHistorique from "hooks/useHistorique";
import KjosSyncButton from "components/KjosSyncButton";
import { setAlert, setCount, setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import { useNavigate } from "react-router-dom";
import { ARTICLE_CREATE_PATH, SIGNALEMENT_DETAIL_URL } from "config/PathUrl";
import { FANCY_BLUE, FANCY_GREEN } from "config/color";
import Article from "components/Article";

const ListArticle = () => {
  const [value, setValue] = React.useState("1");
  const isSuperAdmin = useRole("admin", "superadmin");
  const dispatch = useDispatch(); 
  const { getUser } = useUser();
  const { updateSignalement, getAdminSignalement } = useSignalement(); // get data from server
  const { postData, putData, getOneData, getData } = useHttp(); // get data from server
  const [dialogMessage, setDialogMessage] = useState(""); // open snackbar
  const [dialogMessageColor, setDialogMessageColor] = useState(""); // open snackbar
  const { setHistorique } = useHistorique();
  const router = useNavigate();
  const { postResult, getResult, deleteResult, editResult, update } = useSelector(
    (state) => state.authentication
  ); // get result state
  const [motif, setMotif] = useState("add"); // add, edit
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
  const [confirmDelete, setConfirmDelete] = useState(false); // open snackbar
  const [dialogBody, setDialogBody] = useState(""); // open snackbar
  const [dialogTitle, setDialogTitle] = useState(""); // open snackbar
  const [signalements, setSignalements] = useState([]); // open snackbar
  const { page, rowsPerPage, alert, count, loading } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
   const [article, setArticle] = useState([]);

   const onChangePage = (event, newPage) => {
     dispatch(setPage(newPage));
     
   };

   
   

  useEffect(() => {
      dispatch(setLoading(true)); // setLoading
      getData(ADMIN_ARTICLE_URL + `?page=${page}&per_page=6&order_by&order=desc`).then((data) => {
       if (data?.error) {
         return;
       }
       
       dispatch(setCount(data?.count));
       setArticle(data?.data);
      dispatch(setLoading(false)); // setLoading

     });

  },[dispatch, page, rowsPerPage, update, count]);


  useEffect(() => {
    return () => {
      setOpenSnackBar(false);
      dispatch(
        setAlert({
          open: false,
          message: "",
        }));
    };
  },[])

  
  return (
    <KjosAuthorized permission="articles_management">
      <KjosHeader>Liste des articles | ANSSI</KjosHeader>
      <Box component={"main"} sx={{ flexGrow: 1, py: 3 }}>
        <Container maxWidth={"xl"}>
          <Stack sx={{ px: 5 }}>
            <KjosTitle icon={<DescriptionIcon />} title="Articles" subtitle="Gestion des Articles" iconBgColor={FANCY_BLUE} />
          </Stack>
          <br />
          <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
      <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"}>
        <Stack spacing={3} direction={"column"}>
          <Stack direction={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
            {/* <Typography variant="h6">Signalements non traités</Typography> */}
            <div>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Button
                  color="error"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  disabled={itemsSelected.length >= 1 && true}
                  variant="contained"
                  onClick={() => navigate(ARTICLE_CREATE_PATH)}
                  sx={{ bgcolor: FANCY_BLUE, color: "white" }}
                  >
                  Ajouter
                </Button>

                <KjosSyncButton /> 
              </Stack>
            </div>
          </Stack>


            <Article items={article} count={count} page={page} onChangePage={onChangePage} loading={loading} />
        </Stack>
      </Container>

    </Box>


          {/* </Box> */}
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default ListArticle;
