import { Box, Button, Container, Grid, Paper, Stack, SvgIcon, Typography } from "@mui/material";
import { writeFile, utils } from "xlsx";
import KjosAuthorized from "components/KjosAuthorized";
import KjosHeader from "components/KjosHeader";
import KjosTitle from "components/KjosTitle";
import { FANCY_BLUE, FANCY_GREEN, CREATE_COLOR } from "config/color";
import FlagIcon from "@mui/icons-material/Flag";
import { Link, useLocation } from "react-router-dom";
import { RAPPORT_LIST_PATH } from "config/PathUrl";
import { Group } from "@mui/icons-material";
import { CheckCircle, Error, Warning } from "@mui/icons-material";
import { People } from "@mui/icons-material";
import ReportIcon from "@mui/icons-material/Report";
import KjosSelect5 from "components/KjosSelect5";
import { useEffect, useRef, useState } from "react";
import KjosListTransfert from "../../components/KjosListTransfert";
import useSignalement from "../../hooks/useSignalement";
import { Skeleton } from "@mui/joy";
import { Send } from "@mui/icons-material";
import useUser from "../../hooks/useUser";
import KjosNextPage2 from "../../components/KjosNextPage2";
import useHttp from "../../hooks/useHttp";
import { ADMIN_PLAINTE_RAPPORT_URL, ADMIN_RAPPORT_URL } from "../../config/RoutesUrl";
import { useDispatch } from "react-redux";
import { setAlert } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import KjosRichText from "components/KjosRichText";
import KjosInput2 from "components/KjosInput2";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import scrollToTop from "controller/scrollToTop";

const destinataires = [
  {
    id: 0,
    label: "Selon les utilisateurs",
  },
  {
    id: 1,
    label: "Selon les groupes",
  },
  {
    id: 2,
    label: "Personnaliser",
  },
];

export default function RapportSendByFilter() {
  const [currentDestinataire, setCurrentDestinataire] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const { getCurrentGroupe, getAllGroup, getAllSignalement } = useSignalement();
  const { getAllAdmins } = useUser();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [currentGroupe, setCurrentGroupe] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [customSend, setCustomSend] = useState(false);
  const filter = location.state?.filter;
  const type = location.state?.type;
  const groupe_by_date_start = location.state?.groupe_by_date_start;
  const groupe_by_date_end = location.state?.groupe_by_date_end;
  const filterValue = location.state?.filterValue;
  const [sendToGroup, setSendToGroup] = useState("admins");
  const [loading, setLoading] = useState(false);
  const { getData, postData } = useHttp();
  const dispatch = useDispatch();
  const { json_to_sheet, book_append_sheet } = utils;
  const workbook = utils.book_new();
  const signalement_id = location.state?.signalement_id;
  const signalement_code = location.state?.signalement_code;

  // Ref fileRef, audioRef, videoRef
  const fileRef = useRef();
  const audioRef = useRef();
  const videoRef = useRef();

  const [customUser, setCustomUser] = useState({
    id: 1,
    subject: "",
    email: "",
    message: "",
    destinataires: [],
  });

  const handleDestinataireChange = async (e) => {
    setFadeIn(false); // Reset the fade-in animation
    setTimeout(() => {
      setFadeIn(true); // Trigger the fade-in animation
    }, 0);
    setCurrentDestinataire(e.target.value);
    

    if (e.target.value == 0) {
      setSendToGroup("admins");
      setCustomSend(false);
      getAllAdmins().then((data) => {
        setData(data);
      });
    } else if (e.target.value == 1) {
      setCustomSend(false);
      setSendToGroup("groups");
      getAllGroup().then((data) => {
        setData(data);
      });
    } else if (e.target.value == 2) {
      setData([]);
      setCustomSend(true);
    }
  };

  // When we check in left list
  const handleValue = (e) => {
    setValue(e);
    
  };

  const generateReport = async () => {
    let send    
    let filterMsg = (filter === "groupe_by_type_signalement")? "Selon le type du signalement" : ((filter === "groupe_by_status")? "Selon le status" : ((filter === "groupe_by_user")? "Selon l'utilisateur" : ""));  
    let message = "RAPPORT " + filterMsg  + " du " + groupe_by_date_start + " au " + groupe_by_date_end;

    const formData = new FormData();

    formData.append("signalement_id", signalement_id);
    formData.append("order", 'desc');
    formData.append("order_by", 'created_at');
    formData.append("filter", filter);
    formData.append("filterValue", filterValue);
    formData.append("groupe_by_date_start", groupe_by_date_start);
    formData.append("groupe_by_date_end", groupe_by_date_end);

    formData.append("destinataires", value.join(","));
    formData.append("message", message);
    formData.append("subject", "RAPPORT");
    formData.append("send_to_group", sendToGroup);

    if(type === "Plainte"){
      send = await postData(formData, ADMIN_PLAINTE_RAPPORT_URL);      
    }else{
      send = await postData(formData, ADMIN_RAPPORT_URL);      
    }
    return send;
  };

  const sendReport = async (e) => {
    let send
    if (value.length > 0) {
      if (customSend) {
        setLoading(true);
        const formData = new FormData();

        // Join value and customUser.destinataires
        const joinedArray = customUser?.destinataires?.filter((dest) => value.includes(dest.id)).map((dest) => dest.email);

        const destinataires = joinedArray.join(",");
        const message = customUser.message;
        const subject = customUser.subject;

        formData.append("destinataires", destinataires);
        formData.append("signalement_id", signalement_id);
        formData.append("message", message);
        formData.append("subject", subject);
        formData.append("file", fileRef.current.files[0] ?? null);
        formData.append("audio", audioRef.current.files[0] ?? null);
        formData.append("video", videoRef.current.files[0] ?? null);


        if(type === "Plainte"){
          send = await postData(formData, ADMIN_PLAINTE_RAPPORT_URL);      
        }else{
          send = await postData(formData, ADMIN_RAPPORT_URL);
        }
        

        if (send?.error) {
          dispatch(
            setAlert({
              open: true,
              message: "Une erreur s'est produite. Si le problème persiste, veuillez contacter l'administrateur.",
              color: "error",
            })
          );
        } else {
          dispatch(
            setAlert({
              open: true,
              message: "Rapport envoyé",
              color: CREATE_COLOR,
            })
          );
          setLoading(false);
        }
        scrollToTop();
      } else {
        setLoading(true);

        const send = await generateReport();
        

        if (send?.error) {
          dispatch(
            setAlert({
              open: true,
              message: "Une erreur s'est produite. Si le problème persiste, veuillez contacter l'administrateur.",
              color: "error",
            })
          );
        } else {
          dispatch(
            setAlert({
              open: true,
              message: "Rapport envoyé",
              color: CREATE_COLOR,
            })
          );
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    // Simulating an API call or other asynchronous operation
    setTimeout(() => {
      getAllAdmins().then((data) => {
        setData(data);
      });
    }, 1000);
  }, [getCurrentGroupe]);

  useEffect(() => {
    setIsLoading(false);
  }, [data]);

  return (
    <KjosAuthorized permission="report_management">
      <KjosNextPage2>
        <KjosAlert label={alert.message} />

        <KjosHeader>rapport | ANSSI</KjosHeader>
        <Box component={"main"} sx={{ flexGrow: 1, py: 3 }}>
          <Container maxWidth={"xl"}>
            <Stack sx={{ p: 5 }}>
              <KjosTitle icon={<ReportIcon />} title="Rapport" subtitle={<>Envoyer le rapport par mail {signalement_code && `pour le signalement ${signalement_code}`}</>}  iconBgColor={FANCY_BLUE} />
            </Stack>
            <br />

            <Stack spacing={3} direction={"column"}>
              <Grid container spacing={2}>
                {/* Select destinataire  type*/}
                <Grid item xs={4}>
                  <KjosSelect5 id="destinataires" label="selectionner le(s) distinataire(s)" name="destinataires" width={"100%"} options={destinataires} onChange={handleDestinataireChange} />
                </Grid>

                <Grid item xs={8}></Grid>

                {/* Title */}
                <Grid item xs={12}>
                  <Typography variant="h6" className={fadeIn ? "fadeIn" : ""} sx={{ textAlign: "center" }}>
                    {" "}
                    Rapport selont le {destinataires[currentDestinataire].label?.toLocaleLowerCase()}
                    {" : "}
                    {currentDestinataire === 0 && currentGroupe}
                  </Typography>
                </Grid>

                {/* Destinataire */}
                {customSend && (
                  <>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                      <Typography variant="body" sx={{ textAlign: "center" }}>
                        Saisissez des adresse mail séparées par des virgule
                      </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={5}>
                      <KjosInput2 type="text" id="email" value={customUser.email} onChange={(e) => setCustomUser({ ...customUser, email: e.target.value })} width={"100%"} />
                    </Grid>

                    {/* Add  destinataire */}
                    <Grid item xs={1}>
                      <Button
                        className={fadeIn ? "fadeIn" : ""}
                        startIcon={
                          <SvgIcon fontSize="small">
                            <PlusIcon />
                          </SvgIcon>
                        }
                        variant="contained"
                        disabled={customUser.email?.length > 0 ? false : true}
                        onClick={(e) => {
                          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          const emails = customUser.email.split(",").map((email) => email.trim());
                          const validEmails = emails.filter((email) => emailRegex.test(email));

                          const isValidFormat = customUser.email.trim() === "" || validEmails.length === emails.length;

                          if (!isValidFormat) {
                            alert("Veuillez entrer des adresses e-mail valides");
                            return;
                          }

                          const exists = data.some((obj) => Object.values(obj).includes(customUser?.email));
                          if (exists) {
                            alert("Ce destinataire existe déjà");
                            return;
                          }

                          const newData = emails.map((email) => ({ id: Math.floor(Math.random() * 10000000000), label: email }));

                          setData([...data, ...newData]);

                          setCustomUser({
                            ...customUser,
                            email: "",
                            destinataires: [...customUser.destinataires, ...newData.map(({ id, label }) => ({ email: label, id }))],
                          });
                        }}
                        sx={{ bgcolor: FANCY_GREEN, color: "white", height: "40px" }}
                      >
                        Ajouter
                      </Button>
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </>
                )}

                {/* Transfert list */}
                <Grid item xs={12}>
                  {isLoading ? "Lof" : <KjosListTransfert data={data} handleValue={handleValue} />}
                </Grid>

                {/* custom email Content */}
                {customSend && (
                  <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} sx={{ height: "15rem", overflow: "auto" }}>
                      <Stack direction={"column"} spacing={2}>
                        <KjosInput2 type="text" id="objet" label="Objet" onChange={(e) => setCustomUser({ ...customUser, subject: e.target.value })} width={"100%"} />
                        <KjosRichText onUpdate={(value) => setCustomUser({ ...customUser, message: value })} fileRef={fileRef} audioRef={audioRef} videoRef={videoRef} />
                      </Stack>
                    </Grid>
                    <Grid item xs={3}></Grid>
                  </Grid>
                )}
              </Grid>
              <Stack spacing={2} direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{ width: "100%" }}>
                {/* Loading button */}
                {loading && (
                  <Typography variant="body2" className={fadeIn ? "fadeIn" : ""} sx={{ textAlign: "center" }}>
                    Envoie...
                  </Typography>
                )}

                {/* Send button */}
                <Button
                  className={fadeIn ? "fadeIn" : ""}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <Send />
                    </SvgIcon>
                  }
                  variant="contained"
                  disabled={value?.length > 0 ? false : true}
                  onClick={sendReport}
                  sx={{ bgcolor: FANCY_GREEN, color: "white", height: "40px" }}
                >
                  Envoyer
                </Button>
              </Stack>
            </Stack>
          </Container>
        </Box>
      </KjosNextPage2>
    </KjosAuthorized>
  );
}
