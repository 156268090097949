export const BASE_URL_IMAGE = "/images";
export const logo = "/images/image/logo.png";
export const logo_guinee = "/images/image/guinee.png";
export const icon_users = "/images/image/users.png";
export const icon_admins = "/images/image/admins.png";
export const icon_dashboard = "/images/image/dashboard.png"; 
export const icon_settings = "/images/image/settings.png";
export const icon_signalements = "/images/image/signalements.png";
export const image_agence = "/images/image/study_abroad_pana_1.png";
export const image_agence2 = "/images/image/study_abroad_pana_2.png";
export const image_agence3 = "/images/image/study_abroad_pana_3.png";
export const signalement_assistance_icon = "/images/icon/signalement_assistance_icon.png";
export const signalement_icon = "/images/icon/signalement_icon.png";
export const upload_file_icon = "/images/icon/upload_file_icon.png";
export const delete_icon = "/images/icon/delete_icon.png";
export const signalement_anonyme_icon = "/images/icon/signalement_anonyme_icon.png";
export const image_anonyme = "/images/profil/avatar_anonyme.jpg";
export const avatar_anonyme = "/images/profil/image_anonyme.png";
export const avatar_anonyme_boy = "/images/profil/avatar_anonyme_boy.jpg";
export const avatar_anonyme_girl = "/images/profil/avatar_anonyme_girl.jpg";
export const article_img_1 = "/images/articles/product-1.png";
export const article_img_2 = "/images/articles/product-2.png";
export const article_img_3 = "/images/articles/product-3.png";
