import KjosHeader from "components/KjosHeader";
import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import useHttp from "hooks/useHttp";
import { USER_URL } from "config/RoutesUrl";
import UserDetail from "pages/user/userDetail";
import KjosAuthorized from "components/KjosAuthorized";
import { useLocation, useNavigate } from "react-router-dom";
import KjosTitle from "components/KjosTitle";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import { FANCY_BLUE } from "config/color";
const EditUser = () => {

  const router = useLocation()
  const [user, setUser] = useState({})
  const { getData, postData, putData, deleteData, getOneData } = useHttp();

  useEffect(() => {
    const id = router.state.id;
    var user = getOneData(id, USER_URL).then((res) => {
      console.log(res)
      if (!res?.error) { 
        setUser(res?.data);        
      }
    });
  },[router])
  return(
    <KjosAuthorized permission="edit_user">
  
    <KjosHeader>
    Modiefier un utilisateur | ANSSI
    </KjosHeader>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
      <KjosTitle icon={<UserIcon />} title="Utilisateur" subtitle="Détail de l'utilisateur" iconBgColor={FANCY_BLUE} />
        <br />
          
        <Stack spacing={3}>
          <div>
            <Grid container 
            spacing={3}>
              <Grid item 
              xs={12}>
                <UserDetail user = {user} />
              </Grid>
              {/* <Grid item 
              xs={12}>
                <SettingsPassword  userId = {router.state.id}/>
              </Grid> */}
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  </KjosAuthorized>)
};


export default EditUser;
