export const BASE_URL = "https://api-gds.radiangn.com";
export const BASE_URL_API = BASE_URL + "/api/v1";
export const LOGIN_URL = "/login";
export const REGISTER_URL = "/register";
export const LOGOUT_URL = "/admin/logout";
export const CSRF_COOKIES = "/sanctum/csrf-cookie";
export const IS_AUTH_URL = "/admin/isauth";
export const ADMIN_PASSWORD_URL = BASE_URL_API + "/admin-password";
export const ADMIN_PASSWORD_LINK_URL = BASE_URL_API + "/admin-password-link";
export const ADMIN_RESET_PASSWORD_URL = BASE_URL_API + "/reset-admin-password";
export const CLASSE_SIGNALEMENT_CREATE_URL = BASE_URL_API + "/admin/classes_signalement";
export const CLASSE_SIGNALEMENT_GET_URL = BASE_URL_API + "/admin/classes_signalement";
export const SOURCE_DE_DONNEE_URL = BASE_URL_API + "/admin/sources";
export const USER_URL = BASE_URL_API + "/admin/users";
export const ADMIN_USER_URL = BASE_URL_API + "/admin/admins";
export const ADMIN_SIGNALLEMENT_URL = BASE_URL_API + "/admin/signalements";
export const ADMIN_SIGNALLEMENT_USER_URL = BASE_URL_API + "/admin/signalements/user";
export const ADMIN_TYPE_SIGNALEMENT_URL = BASE_URL_API + "/admin/types_signalement";
export const ADMIN_HABILETE_URL = BASE_URL_API + "/admin/habiletes";
export const ADMIN_CIRCUIT_TRAITEMENT_URL = BASE_URL_API + "/admin/circuit_traitement";
export const SIGNIN_CONFIRM_RECAPTCHA_URL = BASE_URL_API + "/signin/signinConfirm/recaptcha";
export const ADMIN_HISTORIQUE_URL = BASE_URL_API + "/admin/historique";
export const ADMIN_INDEX_TRAITEMENT = BASE_URL_API + "/admin/index_traitement";
export const ADMIN_DASHBOARD_URL = BASE_URL_API + "/admin/dashboard/signalement";
export const ADMIN_DASHBOARD_PLAINTE_URL = BASE_URL_API + "/admin/dashboard/plainte";
export const ANONYME_HISTORIQUE_URL = BASE_URL_API + "/historique";
export const ADMIN_COMMENTAIRE_URL = BASE_URL_API + "/admin/commentaires";
export const ADMIN_AUTORISATION_URL = BASE_URL_API + "/admin/autorisations";
export const ADMIN_PERMISSIONS_URL = BASE_URL_API + "/admin/permissions";
export const ADMIN_HABILETE_PERMISSIONS_URL = BASE_URL_API + "/admin/habilete/permissions";
export const ADMIN_CURRENT_PERMISSIONS_URL = BASE_URL_API + "/admin/current/permissions";
export const ADMIN_ADMIN_ACTION_URL = BASE_URL_API + "/admin/adminactions";
export const ADMIN_SIGNALLEMENT_QUERY_URL = BASE_URL_API + "/admin/signalements_query";
export const ADMIN_COMMUNE_URL = BASE_URL_API + "/admin/communes";
export const USER_COMMUNE_URL = BASE_URL_API + "/user/communes";
export const ADMIN_PLAINTE_URL = BASE_URL_API + "/admin/plaintes";
export const USER_PLAINTE_URL = BASE_URL_API + "/user/plaintes";
export const ADMIN_CMS_URL = BASE_URL_API + "/admin/cms";
export const ADMIN_ARTICLE_URL = BASE_URL_API + "/admin/articles";
export const ADMIN_RAPPORT_URL = BASE_URL_API + "/admin/rapport";
export const ADMIN_PLAINTE_RAPPORT_URL = BASE_URL_API + "/admin/plainte-rapport";
export const ADMIN_NOTIFICATION_URL = BASE_URL_API + "/admin/notifications";

export const CMS_GET = BASE_URL_API + "/cms/get";
export const CMS_HEADER = BASE_URL_API + "/cms/header";
export const CMS_BODY = BASE_URL_API + "/cms/body";
export const CMS_FOOTER = BASE_URL_API + "/cms/footer";

export const INIT_PASSWORD_URL = BASE_URL_API + "/init/password";


export const USER_CLASSE_SIGNALEMENT_GET_URL = BASE_URL_API + "/user/classes_signalement";

