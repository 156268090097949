import KjosHeader from "components/KjosHeader";
import { Box, Button, Container, Stack, SvgIcon } from "@mui/material";
import SignalementDetailLayout from "pages/signalements/layout/SignalementDetailLayout";
import KjosAuthorized from "components/KjosAuthorized";
import { DELETE_COLOR, FANCY_BLUE, FANCY_ORANGE } from "config/color";
import KjosTitle from "components/KjosTitle";
import DescriptionIcon from "@mui/icons-material/Description";
import { useLocation, useNavigate } from "react-router-dom";
import Article from "components/Article";
import useHttp from "hooks/useHttp";
import { useEffect, useState } from "react";
import { ADMIN_ARTICLE_URL } from "config/RoutesUrl";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import { ARTICLE_EDIT_PATH, ARTICLE_LIST_PATH } from "config/PathUrl";
import KjosAlert from "components/KjosAlert";
import { Delete } from "@mui/icons-material";
import KjosDialog2 from "components/KjosDialog2";
import { setAlert } from "store/SignalementReducer";
import { useDispatch } from "react-redux";

const DetailArticle = () => {
  const location = useLocation();
  const article_id = location.state?.article_id;
  const { getOneData, deleteData2 } = useHttp();
  const [article, setArticle] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const submitDialogCallback = async () => {
    // Deletd the article
    const deleteArticle = await deleteData2(ADMIN_ARTICLE_URL + "/" + article_id);
    
    if (deleteArticle.error) {
      return
    }else{
      dispatch(setAlert({ open: true, message: "Article supprimé avec succès", color: DELETE_COLOR }));
      navigate(ARTICLE_LIST_PATH);
    setOpen(false);
  }

  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getOneData(article_id, ADMIN_ARTICLE_URL).then((data) => {
      
      setArticle(data.data);
    });
  }, [getOneData]);
  return (
    <KjosAuthorized permission="articles_management">
      <KjosHeader>Article - Détail | ANSSI</KjosHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <KjosAlert label={alert.message} />

          <KjosTitle icon={<DescriptionIcon />} title="Article" subtitle="Détail de l'articles" iconBgColor={FANCY_BLUE} />
          <br />

          <Stack spacing={3} direction={"row"}>
            <Button
              startIcon={
                <SvgIcon fontSize="small">
                  <PencilIcon />
                </SvgIcon>
              }
              variant="contained"
              onClick={() => navigate(ARTICLE_EDIT_PATH, { state: { article_id: article?.id } })}
              sx={{ bgcolor: FANCY_BLUE, color: "white", my: 3 }}
            >
              Modifier
            </Button>
            <Button
              startIcon={
                <SvgIcon fontSize="small">
                  <Delete />
                </SvgIcon>
              }
              variant="contained"
              onClick={() => setOpen(true)}
              sx={{ bgcolor: FANCY_ORANGE, color: "white", my: 3 }}
            >
              Supprimer
            </Button>
          </Stack>
          <br />
          <Stack spacing={3}>
            <Article items={article} detail />
          </Stack>
        </Container>
      </Box>
      <KjosDialog2 title={"Suppression de l'article"} body={"Voulez-vous vraiment supprimer cet article"} button="Oui" submitCallback={submitDialogCallback} open={open} handleClose={handleDialogClose}  />
    </KjosAuthorized>
  );
};

export default DetailArticle;
