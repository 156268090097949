import { useState } from "react";
import useHttp from "hooks/useHttp";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
  MenuItem,
  Typography,
  Stack,
  Chip,
} from "@mui/material";

import useUser from "hooks/useUser";
import KjosInput2 from "components/KjosInput2";
import KjosSnacBar from "components/KjosSnackBar";
import { useRef } from "react";
import { CREATE_COLOR, FANCY_GREEN, MAIN_BLUE, MAIN_GRAY } from "config/color";
import getFileToTransfert from "controller/getFileToTransfert";
import getDefaultImage from "controller/getDefaultImage";
import KjosSelect4 from "components/KjosSelect4";
import { useEffect } from "react";

import KjosHeader from "components/KjosHeader";
import { Container } from "@mui/material";
import KjosInputFile from "components/KjosInputFile";
import {
  ADMIN_SIGNALLEMENT_URL,
  ADMIN_TYPE_SIGNALEMENT_URL,
  ADMIN_ADMIN_ACTION_URL,
  SOURCE_DE_DONNEE_URL,
} from "config/RoutesUrl";
import useSignalement from "hooks/useSignalement";
import useHistorique from "hooks/useHistorique";
import KjosAuthorized from "components/KjosAuthorized";
import KjosTextArea from "components/KjosTextArea";
import {upload_file_icon} from "config/image";

import { styled } from '@mui/material/styles';
import { setAlert } from "store/SignalementReducer";
import { useNavigate } from "react-router-dom";
import KjosTitle from "components/KjosTitle";
import BellAlertIcon from "@heroicons/react/24/solid/BellAlertIcon";
import useRole from "hooks/useRole";

const Image = styled('img')({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const AddSignalement = (props) => {
  const dispatch = useDispatch();
  const { getData, postData} = useHttp(); // get data from server
  const { createSignalement } = useSignalement(); // get data from server
  const router = useNavigate();
  const { setHistorique } = useHistorique();
  const [typeSignalement, setTypeSignalement] = useState([]);
  const [sourceSignalement, setSourceSignalement] = useState([]);
  const [signalement, setSignalement] = useState([]);
  const photoRef = useRef();
  const photoCleanedRef = useRef(false);
  const formRef = useRef();
  const fileListRef = useRef();
  const errorFileListRef = useRef();
  const refImage = useRef();
  const mainImageRef = useRef();
  const isSuperAdmin = useRole("admin", "superadmin");

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 5000,
  });

  <KjosHeader>
    Créer un signalement | ANSI
  </KjosHeader>
  const { getUser } = useUser();
  useEffect(() => {
    async function fetchData() {
      const sign = (await getData(ADMIN_TYPE_SIGNALEMENT_URL))?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));

      const src = (await getData(SOURCE_DE_DONNEE_URL))?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));

      setTypeSignalement(sign);
      setSourceSignalement(src);
    }

    fetchData(); // get data when component is mounted
  }, []);

  const handleOncloseSanackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formData2 = new FormData();
    formData2.append("type_signalement_id", parseInt(formData.get("type_signalement_id")));
    formData2.append("source_signalement_id", parseInt(formData.get("source")));
    formData2.append("source_id", parseInt(formData.get("source_id")));
    formData2.append("localites", formData.get("localites")?.toLocaleLowerCase());
    formData2.append("element_en_cause", formData.get("element_en_cause"));
    formData2.append("type", (isSuperAdmin ? "superadmin" : "admin"));
    formData2.append("description", formData.get("description"));
    formData2.append("statut", "new");
    // formData2.append("user_id", getUser("admin")?.user?.id);
    formData2.append("admin_id", getUser("admin")?.user?.id);
    formData2.append("habilete_position", 0);
    Array.from(mainImageRef.current.files).forEach((file) => formData2.append("preuve[]", file));
   
    const creatSign = await createSignalement(formData2, ADMIN_SIGNALLEMENT_URL);

    
    if (creatSign?.error) {
      setSnackbar({
        open: true,
        message: creatSign?.message,
        severity: "error",
        autoHideDuration: 5000,
      });
    } else {

      formRef.current.reset();
      mainImageRef.current.value="";
      fileListRef.current.innerHTML = "";
      console.log( mainImageRef.current.file)
      // HistoriqueFenre
      const response = await setHistorique(
        "new",
        creatSign?.data?.id,
        getUser("admin")?.user?.id,
        "L'administrateur " + getUser("admin")?.user?.nom + " a créé le signalement: "
      );
      dispatch(setAlert({
        open: true,
        message: "Le signalement a été enregistré avec succès",
        color: CREATE_COLOR,
      }))
      router(-1);
      // snackbar?.data
      // setSnackbar({
      //   open: true,
      //   message: creatSign?.message,
      //   severity: "success",
      //   autoHideDuration: 5000,
      // });
    }
    setSignalement(creatSign?.data);
  };

  return (
    <KjosAuthorized permission="create_signalement">
      <KjosHeader>
        Créer un signalement | ANSSI
      </KjosHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <KjosTitle icon={<BellAlertIcon />} title="Signalement" subtitle="Ajout d'un nouveau signalement" iconBgColor={FANCY_GREEN} />
          <br />
          
          <form onSubmit={handleFormSubmit} ref={formRef}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6} lg={4}>
                <Card sx={{ bgcolor: MAIN_GRAY, height: "100%" }}>
                <CardHeader
                    subheader="Veuillez sélectionner une photo"
                    title={<div>Photo </div>}
                    sx={{backgroundColor: FANCY_GREEN, color: "white"}}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {/* <KjosRadioButton label={label1} label_desc={label_desc1} width="100%" /> */}

                      <Stack
                        direction={"column"}
                        sx={{ mt: 3, width: "100%" }}
                        justifyContent={"center"}
                        alignItems={"flex-start"}
                      >
                        <Typography variant="body1">
                          Joindre une évidence 10Mo max (.pdf, txt, docx, xlsx, jpg, png)
                        </Typography>
                        <KjosInputFile
                          width="100%"
                          height="15rem"
                          component={
                            <Image src={upload_file_icon} alt="upload a file" />
                          }
                          accept=".pdf, .txt, .docx, .xlsx, .jpg, .png"
                          id="evidence"
                          name="evidence"
                          border={`${"1px solid " + MAIN_BLUE}`}
                          label=""
                          fileListRef={fileListRef}
                          errorFileListRef={errorFileListRef}
                          multiple={true}
                          refImage={refImage}
                          printImage={true}
                          mainImageRef={mainImageRef}
                        />
                      </Stack>

                      <div className="mt-2 bg-blue-green d-flex flex-column justify-content-center align-items-start">
                        <div ref={errorFileListRef}></div>
                        <div ref={fileListRef}></div>
                      </div>

                      <div ref={refImage} />
                      <br />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid xs={12} md={6} lg={8}>
                <Card sx={{ bgcolor: MAIN_GRAY }}>
                  <CardHeader
                    subheader="Renseignez les informations du signalement"
                    title={<div>Signalement </div>}
                    sx={{backgroundColor: FANCY_GREEN, color: "white"}}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                      <Grid container spacing={3}>
                        <Grid xs={12} md={6}>
                          <KjosInput2
                            type="text"
                            id="element_en_cause"
                            label="Element en cause"
                            name="element_en_cause"
                            width={"100%"}
                          />
                        </Grid>

                        <Grid xs={12} md={6}>
                          <KjosInput2
                            type="text"
                            id="localites"
                            label="Lieu des faits"
                            name="localites"
                            width={"100%"}
                          />
                        </Grid>

                        <Grid xs={12} md={6}>
                          <KjosInput2
                            type="date"
                            id="date"
                            label="Date des faits"
                            name="date_naissance"
                            width={"100%"}
                            max={new Date().toISOString().split("T")[0]}
                          />
                        </Grid>

                        <Grid xs={12} md={6}>
                          <KjosSelect4
                            id="type_signalement_id"
                            label="Type de signalement "
                            name="type_signalement_id"
                            width={"100%"}
                            options={typeSignalement}
                          />
                        </Grid>

                        <Grid xs={12} md={6}>
                          <KjosSelect4
                            id="source_id"
                            label="Source des données "
                            name="source_id"
                            width={"100%"}
                            options={sourceSignalement}
                          />
                        </Grid>

                        <Grid xs={12} md={12}>
                          <KjosTextArea
                            type="text"
                            name="description"
                            id="description"
                            label="description"
                            required
                            width={"100%"}
                            style={{ height: "10rem" }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions sx={{ justifyContent: "flex-end" }}>
                    <Button type="submit" variant="contained" sx={{ bgcolor: FANCY_GREEN }}>
                      Enregistrer
                    </Button>
                  </CardActions>
                </Card>

                <KjosSnacBar
                  open={snackbar?.open}
                  message={snackbar?.message}
                  severiry={snackbar?.severity}
                  onClose={handleOncloseSanackbar}
                  autoHideDuration={snackbar?.autoHideDuration}
                />
              </Grid>
            </Grid>
          </form>
        </Container>

        <KjosSnacBar
          open={snackbar?.open}
          message={snackbar?.message}
          severity={snackbar?.severity}
          onClose={handleOncloseSanackbar}
          autoHideDuration={snackbar?.autoHideDuration}
        />
      </Box>
    </KjosAuthorized>
  );
};

export default AddSignalement;
